import { render, staticRenderFns } from "./TravelContractThirdPartyPayment.vue?vue&type=template&id=10554752&scoped=true&"
import script from "./TravelContractThirdPartyPayment.vue?vue&type=script&lang=js&"
export * from "./TravelContractThirdPartyPayment.vue?vue&type=script&lang=js&"
import style0 from "./TravelContractThirdPartyPayment.vue?vue&type=style&index=0&id=10554752&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10554752",
  null
  
)

export default component.exports